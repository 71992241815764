import React from "react";

interface CloseIconProps {
  className: string;
}

export const CloseIcon = ({ className }: CloseIconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16 5.20857L14.7914 4L10 8.79143L5.20857 4L4 5.20857L8.79143 10L4 14.7914L5.20857 16L10 11.2086L14.7914 16L16 14.7914L11.2086 10L16 5.20857Z"
      fill="fill-current"
    />
  </svg>
);

export default CloseIcon;
