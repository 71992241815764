import React, { FC } from "react";
import {Country} from "../../types";

export type FlagsProps = {
  country?: Country;
  className?: string;
};

export const Flag: FC<FlagsProps> = ({ country = "be", className }) => {
  return (
    <img
      width={20}
      height={20}
      className={`flags ${className}`}
      alt={`Country ${country}`}
      src={`/flags/${country}.svg`}
    />
  );
};
