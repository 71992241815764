import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import { selectLatestCartItem } from "../../../store/reducers/cartReducer";
import Modal from "../../ui/Modal";
import Title from "../../ui/Title";
import { Button } from "../../ui/Button";
import { Fabric } from "../../../types";
import CompleteYourLook from "./CompleteYourLook";
import { getPrismicTranslation } from "../../../utils";
import { selectLanguage } from "../../../store/reducers/uiReducer";
import PrismicTranslation from "../../PrismicTranslation";

interface AddToCartModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const AddToCartModal = ({ isOpen, setIsOpen }: AddToCartModalProps) => {
  const navigate = useNavigate();
  const language = useAppSelector(selectLanguage);
  const cartItem = useAppSelector(selectLatestCartItem);

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Title icon="check" label="add_to_cart_modal_title" />
      {cartItem && (
        <div className="w-full bg-confirm p-4 border border-gray text-sm">
          <div className="flex flex-wrap items-center">
            <div className="flex pr-4">
              <img
                className="w-48 h-48 object-contain"
                src={cartItem.productModelPicture || "/placeholder.png"}
                alt={getPrismicTranslation(
                  language,
                  "name",
                  cartItem.product?.data,
                )}
              />
            </div>
            <div className="flex flex-col flex-wrap gap-4">
              <p className="flex flex-wrap gap-2">
                <strong>product:</strong>{" "}
                {getPrismicTranslation(
                  language,
                  "name",
                  cartItem.productGroup?.data,
                )}
              </p>
              <p>
                <strong>fit:</strong>{" "}
                {getPrismicTranslation(
                  language,
                  "name",
                  cartItem.product?.data,
                )}
              </p>
              <p className="flex flex-wrap items-center gap-2">
                <strong>fabric(main):</strong>
                <span
                  className="w-2 h-2 rounded-full border"
                  style={{
                    backgroundColor: (cartItem.fabric?.main as Fabric)?.data
                      .web_format,
                  }}
                />
                {getPrismicTranslation(
                  language,
                  "name",
                  cartItem.fabric?.main?.data,
                )}
              </p>
              <p className="flex flex-wrap items-center gap-2">
                <strong>fabric(contrast):</strong>
                <span
                  className="w-2 h-2 rounded-full border"
                  style={{
                    backgroundColor: (cartItem.fabric?.contrast as Fabric)?.data
                      .web_format,
                  }}
                />
                {getPrismicTranslation(
                  language,
                  "name",
                  cartItem.fabric?.contrast?.data,
                )}
              </p>
              <div className="flex flex-wrap gap-2">
                <strong>options:</strong>
                <ul>
                  {cartItem.productOptions?.map((option) => (
                    <li key={option.id}>
                      {getPrismicTranslation(language, "name", option?.data)}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-wrap mt-4 gap-4">
        <Button
          icon="cart"
          onClick={() => navigate("/cart")}
          label="view_cart"
        />
        <div
          onClick={() => setIsOpen(false)}
          className="cursor-pointer flex items-center gap-2 m-2 underline text-text text-sm"
        >
          <span>
            <PrismicTranslation field="continue_shopping" simple />
          </span>
        </div>
      </div>
      <div className="mt-6">
        <CompleteYourLook setIsOpen={setIsOpen} />
      </div>
    </Modal>
  );
};

export default AddToCartModal;
