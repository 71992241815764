import { FC, useEffect, useState } from "react";

export type IconProps = {
  icon?: string;
  size?: "small" | "medium" | "large" | number;
  className?: string;
  onClick?: () => void;
};

const iconSizes = {
  small: 8,
  medium: 16,
  large: 24,
};

export const Icon: FC<IconProps> = ({
  icon,
  className = "",
  size = "medium",
  onClick,
}) => {
  const [svgContent, setSvgContent] = useState<string | null>(null);

  useEffect(() => {
    fetch(`/icons/${icon}.svg`)
      .then((response) => response.text())
      .then((data) => {
        setSvgContent(data);
      })
      .catch((error) => {
        console.error(`Error loading SVG: ${error}`);
      });
  }, [icon]);
  const sizeLocal = typeof size === "number" ? size : iconSizes[size];

  return (
    <div className={`icon-${icon} h-auto ${className}`} onClick={onClick}>
      {svgContent && (
        <svg
          width={sizeLocal}
          height={sizeLocal}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${sizeLocal} ${sizeLocal}`}
          dangerouslySetInnerHTML={{ __html: svgContent }}
          // @ts-ignore
          fill="currentColor"
        />
      )}
    </div>
  );
};
