import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { OrderInformation } from "../../types";

interface UserState {
  loading?: boolean;
  discount?: number;
  token?: string;
  user: any;
  customer: any;
  orderOptions: any;
  orderInformation?: OrderInformation;
}

const initialState: UserState = {
  loading: false,
  token: undefined,
  user: null,
  customer: null,
  orderOptions: null,
  orderInformation: undefined,
};

export const userSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setDiscount: (state, action: PayloadAction<number>) => {
      state.discount = action.payload;
    },
    setToken: (state, action: PayloadAction<any>) => {
      state.token = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setCustomer: (state, action: PayloadAction<any>) => {
      state.customer = action.payload;
    },
    setOrderOptions: (state, action: PayloadAction<any>) => {
      state.orderOptions = action.payload;
    },
    setOrderInformation: (state, action: PayloadAction<OrderInformation>) => {
      state.orderInformation = action.payload;
    },
  },
});

export const {
  setLoading,
  setDiscount,
  setToken,
  setUser,
  setCustomer,
  setOrderOptions,
  setOrderInformation,
} = userSlice.actions;

export const selectLoading = (state: RootState) => state.user.loading;
export const selectDiscount = (state: RootState) => state.user.discount;
export const selectToken = (state: RootState) => state.user.token;
export const selectUser = (state: RootState) => state.user.user;
export const selectCustomer = (state: RootState) => state.user.customer;
export const selectOrderOptions = (state: RootState) => state.user.orderOptions;
export const selectOrderInformation = (state: RootState) =>
  state.user.orderInformation;

export default userSlice.reducer;
