import React, { useEffect, useState } from "react";

// Define the type for your data
type ProductOption = {
  id: string;
  url: string;
  name: string;
  buying_price: number;
  price: number;
};

type Product = {
  id: string;
  url: string;
  name: string;
  productOptions: ProductOption[];
  price: number;
  buying_price: number;
};

const ProductsTable = () => {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_PATH + "/prismic/table",
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Options</th>
        </tr>
      </thead>
      <tbody>
        {products.map((product) => (
          <tr key={product.id}>
            <td className="border">
              <a href={product.url} target="_blank" rel="noopener noreferrer">
                {product.name}
              </a>
              <div className="ml-auto">€ {product.buying_price}</div>
              <div className="ml-auto">€ {product.price}</div>
            </td>
            <td className="border">
              {product.productOptions.map((option) => (
                <div className="flex p-0.5" key={option.id}>
                  <div>
                    <a
                      href={option.url}
                      target="_blank"
                      className="underline"
                      rel="noopener noreferrer"
                    >
                      {option.name}
                    </a>
                  </div>
                  <div className="ml-auto">€ {option.buying_price}</div>
                  <div className="ml-auto">€ {option.price}</div>
                </div>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ProductsTable;
