import * as React from "react";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Scene as BabylonScene } from "@babylonjs/core/scene.js";
import { CubeTexture } from "@babylonjs/core/Materials/Textures/cubeTexture";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { AbstractMesh, ArcRotateCamera, Color4 } from "@babylonjs/core";
import { Scene } from "react-babylonjs";
import { useAppDispatch } from "../../hooks";
import { productLogoSelected } from "../../store/reducers/uiReducer";

export type SceneEnvironmentProps = {
  cameraTarget: Vector3;
  cameraRadius: number;
  sceneFilename: string | undefined;
  children: React.ReactNode;
};

export const defaultCameraName = "product-camera1";

const SceneEnvironment: React.FC<SceneEnvironmentProps> = (
  props: SceneEnvironmentProps,
) => {
  // let environmentUrl = '/environment.dds'
  let environmentUrl = "/Studio_Softbox_2Umbrellas_cube_specular.dds";
  const { cameraTarget, cameraRadius } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_texturesLoaded, setTexturesLoaded] = React.useState(false);

  const dispatch = useAppDispatch();
  const cubeTextureRef = React.useRef<CubeTexture | undefined>(undefined);
  const cubeTextureCloneRef = React.useRef<CubeTexture | undefined>(undefined);
  const defaultCameraRef = React.useRef<ArcRotateCamera | null>(null);

  const cubeTextureCallback = React.useCallback((node: CubeTexture | null) => {
    if (node) {
      cubeTextureRef.current = node;
      cubeTextureCloneRef.current = node.clone();
      cubeTextureCloneRef.current.name = "cloned texture";
      cubeTextureCloneRef.current.coordinatesMode = Texture.SKYBOX_MODE;
      setTexturesLoaded(true); // trigger render and props assignment
    }
  }, []);

  const onMeshPicked = (mesh: AbstractMesh, _scene: BabylonScene) => {
    if (mesh && mesh?.metadata?.fabricVariant === "logo_mesh") {
      dispatch(productLogoSelected(mesh.name));
    }
  };

  return (
    <Scene
      clearColor={Color4.FromHexString("#f6f7f9")}
      environmentTexture={cubeTextureRef.current}
      environmentIntensity={1.1}
      onMeshPicked={onMeshPicked}
    >
      {!props.sceneFilename && (
        <arcRotateCamera
          setTarget={[Vector3.Zero()]}
          name="loading-camera1"
          allowUpsideDown={false}
          alpha={Math.PI / 2}
          beta={Math.PI / 2}
          radius={2.5}
          target={Vector3.Zero()}
          minZ={0.001}
          lowerRadiusLimit={0.25}
          upperRadiusLimit={2.5}
          inertia={0.3}
        />
      )}
      {props.sceneFilename && cameraTarget && (
        <arcRotateCamera
          allowUpsideDown={false}
          name={defaultCameraName}
          id={defaultCameraName}
          alpha={Math.PI / 2}
          beta={Math.PI / 2}
          target={cameraTarget}
          minZ={0.001}
          radius={cameraRadius}
          lowerRadiusLimit={0.35}
          upperRadiusLimit={2.5}
          inertia={0.75}
          speed={0.375}
          wheelDeltaPercentage={0.1}
          wheelPrecision={61.275}
          pinchPrecision={122.5}
          pinchDeltaPercentage={0.01}
          useNaturalPinchZoom={false}
          panningSensibility={4043.7491328381325}
          ref={defaultCameraRef}
        />
      )}
      <hemisphericLight
        name="light1"
        intensity={0.25}
        direction={Vector3.Forward().add(new Vector3(0, 2.0, 0))}
      />
      <cubeTexture
        ref={cubeTextureCallback}
        name="cubeTexture"
        rootUrl={environmentUrl}
        createPolynomials={true}
        format={undefined}
        prefiltered={true}
      />
      {props.children}
    </Scene>
  );
};

export { SceneEnvironment };
