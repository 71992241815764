import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { documentApi } from "../api/prismic";
import { setupListeners } from "@reduxjs/toolkit/query";
import userReducer from "./reducers/userReducer";
import configReducer from "./reducers/configReducer";
import cartReducer from "./reducers/cartReducer";
import storage from "redux-persist/lib/storage";
import uiReducer from "./reducers/uiReducer";
import { persistReducer, persistStore } from "redux-persist";
import { texetApi } from "../api/texet";
import { nestApi } from "../api/nest";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const rootReducer = combineReducers({
  [documentApi.reducerPath]: documentApi.reducer,
  [texetApi.reducerPath]: texetApi.reducer,
  [nestApi.reducerPath]: nestApi.reducer,
  user: userReducer,
  config: configReducer,
  cart: cartReducer,
  ui: uiReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      nestApi.middleware,
      texetApi.middleware,
      documentApi.middleware,
    ),
});
export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
