import { ProductFicheData, ProductOption, ProjectStatus } from "../types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const nestApi = createApi({
  reducerPath: "nestApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_PATH,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    fetchDistributors: builder.query({
      query: () => {
        return `/distributors`;
      },
    }),
    fetchProject: builder.query({
      query: (projectId: string) => {
        return `/project/${projectId}`;
      },
    }),
    fetchUserProjects: builder.query({
      query: (user: string) => {
        return `/project/user/${user}`;
      },
    }),
    fetchUserOrders: builder.query({
      query: (userId: string) => {
        return `/order/user/${userId}`;
      },
    }),
    createTechPack: builder.mutation({
      query: (order: any) => {
        return {
          url: `/order/${order.id}/to-excel`,
          method: "POST",
          body: order,
        };
      },
    }),
    downloadProductFiche: builder.mutation({
      query: ({
        productFicheData,
        cb,
      }: {
        productFicheData: ProductFicheData;
        cb: Function | undefined;
      }) => {
        const {
          productConfig: { id },
          lang,
        } = productFicheData;
        return {
          url: `/productconfig/product-fiche/${id}/${lang}`,
          method: "GET",
          responseHandler: async (response) => {
            if (typeof cb === "function") {
              cb(response);
            }
          },
        };
      },
    }),
    createOrder: builder.mutation({
      query: (order: any) => {
        return {
          url: `/order/create`,
          method: "POST",
          body: order,
        };
      },
    }),
    duplicateProject: builder.mutation({
      query: (orderId: string) => {
        return {
          url: `/project/duplicate/${orderId}`,
          method: "POST",
        };
      },
    }),
    setProjectStatus: builder.mutation({
      query: (payload: { projectId: string; status: ProjectStatus }) => {
        return {
          url: `/project/update`,
          method: "POST",
          body: {
            id: payload.projectId,
            status: payload.status,
          },
        };
      },
    }),
    removeProductConfig: builder.mutation({
      query: (payload: { cartItem: string; project: string }) => {
        return {
          url: `/productconfig/remove`,
          method: "POST",
          body: {
            id: payload.cartItem,
            project: payload.project,
          },
        };
      },
    }),
    createOrUpdateProductConfig: builder.mutation({
      query: (payload: {
        config: any;
        duplicate?: boolean;
        inCart?: boolean;
      }) => {
        const uuid = payload.duplicate ? undefined : payload.config.id;
        const requestBody = JSON.stringify({
          id: uuid,
          project: payload.config.projectId,
          productGroup: payload.config.productGroup?.id,
          product: payload.config.product?.id,
          mainFabric: payload.config.fabric?.main?.id,
          contrastFabric: payload.config.fabric?.contrast?.id,
          productOptions: payload.config.productOptions?.map(
            (option: ProductOption) => {
              return { id: option.id, colorOption: option.data.color_option };
            },
          ),
          inCart: payload.inCart,
          productModelPicture: payload.config.productModelPicture,
          projectLogos: payload.config.projectLogos?.map(
            (image: any) => image.id,
          ),
        });

        return {
          url: `/productconfig/${payload.duplicate ? "duplicate" : "create"}`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: requestBody,
        };
      },
    }),
    // Update ProjectConfig product model render
    updateProductConfigProductImage: builder.mutation({
      query: (payload: {
        config: { id: string; productModelPicture: string };
      }) => {
        return {
          url: "/productconfig/image",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload.config),
        };
      },
    }),
    uploadProjectImage: builder.mutation({
      query: (file: File) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: `/image`,
          method: "POST",
          body: formData,
        };
      },
    }),
    createProject: builder.mutation({
      query: (project: any) => {
        return {
          url: `/project/create`,
          method: "POST",
          body: project,
        };
      },
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useCreateTechPackMutation,
  useDuplicateProjectMutation,
  useFetchDistributorsQuery,
  useLazyFetchProjectQuery,
  useFetchUserOrdersQuery,
  useFetchUserProjectsQuery,
  useRemoveProductConfigMutation,
  useSetProjectStatusMutation,
  useUploadProjectImageMutation,
  useCreateOrUpdateProductConfigMutation,
  useUpdateProductConfigProductImageMutation,
  useDownloadProductFicheMutation,
  useCreateProjectMutation,
} = nestApi;
