import { User, UserManager } from "oidc-client";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setUser } from "../store/reducers/userReducer";
import { Button } from "./ui/Button";
import { selectLoading, setLoading } from "../store/reducers/userReducer";
import PrismicTranslation from "./PrismicTranslation";

const Login = ({ userManager }: { userManager: UserManager }) => {
  const dispatch = useAppDispatch();
  const userLoading = useAppSelector(selectLoading);
  const guestUser = { scope: "guest" } as User;

  const login = async () => {
    try {
      dispatch(setLoading(true));
      await userManager.signinRedirect();
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Login error", error);
    }
  };

  const setUserData = (userData: User) => {
    dispatch(setUser(userData));
  };

  return (
    <div className="grid grid-cols-2 gap-4 md:gap-0 divide-y md:divide-x md:divide-y-0 divide-gray rounded-md lg:mx-auto my-auto px-6 lg:p-9 mx-4 max-w-5xl bg-white">
      <div className="h-full py-6 md:px-6 col-span-2 md:col-span-1 max-w-2xl min-h-90 ">
        <div className="text-text mb-4">
          <PrismicTranslation field={"dealer_signin"} />
        </div>
        <Button
          loading={userLoading}
          label={"dealer_login_button_text"}
          onClick={login}
        />
      </div>
      <div className="h-full py-6 md:px-6 col-span-2 md:col-span-1 md:mt-0 max-w-2xl min-h-90 ">
        <div className="text-text mb-4">
          <PrismicTranslation field={"guest_signin"} />
        </div>
        <Button
          label={"guest_login_button_text"}
          onClick={() => setUserData(guestUser)}
        />
      </div>
    </div>
  );
};

export default Login;
