import {
  getCartItemSizesPrice,
  getPrismicTranslation,
  totalCartProductAmount,
} from "../../utils";
import { Button } from "../ui/Button";
import React from "react";
import { CartProduct, Supplier } from "../../types";
import { removeFromCart } from "../../store/reducers/cartReducer";
import {
  selectProjectId,
  setSelectedConfig,
} from "../../store/reducers/configReducer";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useRemoveProductConfigMutation } from "../../api/nest";
import { selectLanguage } from "../../store/reducers/uiReducer";
import PrismicTranslation from "../PrismicTranslation";

interface DeliveryItemProps {
  cartItem: CartProduct;
  fabricSupplier: Supplier | null;
}

const DeliveryItem = ({ cartItem, fabricSupplier }: DeliveryItemProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const projectId = useAppSelector(selectProjectId);
  const [viewDetails, setViewDetails] = React.useState(false);
  const [removeProductConfig] = useRemoveProductConfigMutation();

  const remove = async () => {
    await removeProductConfig({
      cartItem: cartItem.id,
      project: projectId || "",
    });
    dispatch(removeFromCart(cartItem));
  };

  const editCartItem = () => {
    navigate(`/${projectId}`);
    dispatch(setSelectedConfig({ ...cartItem, isEditing: true }));
  };

  return (
    <div className="flex flex-col gap-2 border-b border-gray p-2">
      <div className="flex justify-between">
        <span className="font-bold text-primary">
          {getPrismicTranslation(
            language,
            "name",
            cartItem?.productGroup?.data,
          )}{" "}
          ({totalCartProductAmount(cartItem)})
        </span>
        <span className="font-bold">
          {getCartItemSizesPrice(cartItem, fabricSupplier).toFixed(2)}€
        </span>
      </div>
      {viewDetails && (
        <>
          <div>
            <div>
              <span className="font-bold">
                <PrismicTranslation field="colors" simple />
              </span>
              <div className="text-sm text-text">
                {getPrismicTranslation(
                  language,
                  "name",
                  cartItem?.fabric?.main?.data,
                )}
              </div>
              <div className="text-sm text-text">
                {getPrismicTranslation(
                  language,
                  "name",
                  cartItem?.fabric?.contrast?.data,
                )}
              </div>
            </div>
          </div>
          <div>
            <span className="font-bold">
              <PrismicTranslation field="features" simple />
            </span>
            <div className="text-sm text-text">
              {cartItem.productOptions?.map((productOption) => (
                <div>
                  {getPrismicTranslation(language, "name", productOption?.data)}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <Button
        onClick={() => setViewDetails(!viewDetails)}
        link
        label={viewDetails ? "hide_details" : "view_details"}
      />
      <Button onClick={editCartItem} link icon="edit" label="edit_design" />
      <Button onClick={remove} link icon="delete" label={"remove"} />
    </div>
  );
};

export default DeliveryItem;
