import Title from "../ui/Title";
import React, { FC, useState } from "react";
import { Distributor } from "../../types";
import { Input } from "../ui/Input";
import PrismicTranslation from "../PrismicTranslation";

interface Props {
  current?: Distributor;
  options: Distributor[];
  onSelect: (field: string, value?: Distributor) => void;
}

const DistributorSelect: FC<Props> = ({ onSelect, options, current }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSelect = (option: Distributor) => {
    onSelect("distributor", option);
    setIsOpen(false);
    setSearchTerm(option.name);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
    onSelect("distributor");
  };

  const handleClear = () => {
    setSearchTerm("");
    setIsOpen(false);
    onSelect("distributor");
  };

  const filteredOptions = () => {
    return options.filter((option) => {
      const distributor = `${option.name} ${option.address} ${option.zip} ${option.city} ${option.country}`;
      return distributor.toLowerCase().includes(searchTerm.toLowerCase());
    });
  };

  return (
    <>
      <Title tertiary className="text-black" label="distributor" />
      <div className="relative cursor-pointer">
        <Input
          type="search"
          placeholder="..."
          value={searchTerm}
          onChange={handleChange}
          onClick={() => setIsOpen(!isOpen)}
        />
        <button
          onClick={handleClear}
          className="absolute rounded-full top-2 right-2"
        ></button>
        {isOpen && (
          <ul className="border border-gray w-full bg-white absolute z-10">
            {filteredOptions().map((option, index) => (
              <li
                key={index}
                onClick={() => handleSelect(option)}
                className={`relative p-1 hover:bg-primary-200 ${
                  current?.id === option.id ? "underline" : ""
                }`}
              >
                <div>
                  {option.name} <br />
                  <span className="relative text-xs">
                    {option.address}, {option.zip} - {option.city},
                    <PrismicTranslation field={option.country} simple />
                  </span>
                </div>
                <div
                  className="absolute bottom-0 inset-0 flex items-baseline"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray" />
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default DistributorSelect;
