import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { Country, CountryLang, ViewLoadingState } from "../../types";

export const defaultCountryLang = "nl-nl" as CountryLang;
export const defaultLang = "nl" as Country;
export const countries: Country[] = ["nl", "en", "fr", "de"];

interface UiState {
  language: Country;
  productLogoPanelOpen: boolean;
  productLogoSelected: string;
  productModelLoadingState: ViewLoadingState;
  activeTab: string;
  translations: object | null;
}

const initialState: UiState = {
  language:
    (window.localStorage.getItem("ui.language") as Country) || defaultLang,
  productLogoPanelOpen: false,
  productLogoSelected: "",
  productModelLoadingState: "idle",
  activeTab: "",
  translations: null,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<Country>) => {
      state.language = action.payload;
      window.localStorage.setItem("ui.language", state.language);
    },
    setTranslation: (state, action: PayloadAction<object>) => {
      state.translations = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
    productLogoPanelOpen: (state, action: PayloadAction<boolean>) => {
      state.productLogoPanelOpen = action.payload;
    },
    productLogoSelected: (state, action: PayloadAction<string>) => {
      state.productLogoSelected = action.payload;
    },
    setProductModelLoadingState: (
      state,
      action: PayloadAction<ViewLoadingState>,
    ) => {
      state.productModelLoadingState = action.payload;
    },
  },
});

export const {
  productLogoPanelOpen,
  productLogoSelected,
  setProductModelLoadingState,
  setActiveTab,
  setLanguage,
  setTranslation,
} = uiSlice.actions;

export function userLangToCountryLang(userLanguage: string): CountryLang {
  if (userLanguage === "nl") {
    return "nl-nl";
  } else if (userLanguage === "fr") {
    return "fr-fr";
  } else if (userLanguage === "de") {
    return "de-de";
  } else if (userLanguage === "en") {
    return "en-us";
  } else if (userLanguage === "uk") {
    return "en-us";
  }
  return defaultCountryLang;
}

export const selectLanguage = (state: RootState) => state.ui.language;
export const selectCountryLanguage = (state: RootState) =>
  userLangToCountryLang(state.ui.language);
export const selectActiveTab = (state: RootState) => state.ui.activeTab;
export const selectProductLogoPanelOpen = (state: RootState) =>
  state.ui.productLogoPanelOpen;
export const selectProductLogoSelected = (state: RootState) =>
  state.ui.productLogoSelected;
export const selectProductModelLoadingState = (state: RootState) =>
  state.ui.productModelLoadingState;
export const selectTranslations =  (state: RootState) =>
  state.ui.translations;

export default uiSlice.reducer;
