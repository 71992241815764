import React from "react";

interface CloseIconProps {
  className: string;
}

export const ShareIcon = ({ className }: CloseIconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.3333 13.3092C14.6578 13.3092 14.0533 13.5502 13.5911 13.9277L7.25333 10.5944C7.29778 10.4096 7.33333 10.2249 7.33333 10.0321C7.33333 9.83936 7.29778 9.65462 7.25333 9.46988L13.52 6.16867C14 6.57028 14.6311 6.81928 15.3333 6.81928C16.8089 6.81928 18 5.74297 18 4.40964C18 3.07631 16.8089 2 15.3333 2C13.8578 2 12.6667 3.07631 12.6667 4.40964C12.6667 4.60241 12.7022 4.78715 12.7467 4.97189L6.48 8.27309C6 7.87149 5.36889 7.62249 4.66667 7.62249C3.19111 7.62249 2 8.6988 2 10.0321C2 11.3655 3.19111 12.4418 4.66667 12.4418C5.36889 12.4418 6 12.1928 6.48 11.7912L12.8089 15.1325C12.7644 15.3012 12.7378 15.4779 12.7378 15.6546C12.7378 16.9478 13.9022 18 15.3333 18C16.7644 18 17.9289 16.9478 17.9289 15.6546C17.9289 14.3614 16.7644 13.3092 15.3333 13.3092Z"
      fill="fill-current"
    />
  </svg>
);

export default ShareIcon;
