import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectConfigLoader,
  selectProjectLogoPlacable,
  selectProjectLogos,
  setProjectLogos,
} from "../../../store/reducers/configReducer";
import { ProjectLogoItem } from "./LogoItem";
import { ConfiguratorSelector } from "../ConfiguratorSelector";
import { productLogoPanelOpen } from "../../../store/reducers/uiReducer";
import { logoNames } from "./config";

const ProjectLogos: React.FC = () => {
  const configLoader = useAppSelector(selectConfigLoader);
  const projectLogos = useAppSelector(selectProjectLogos);
  const projectLogoPlacable = useAppSelector(selectProjectLogoPlacable);
  const dispatch = useAppDispatch();
  const projectLogoNames =
    projectLogos.length > 0
      ? projectLogos.map((curr) => `project_${curr.name}`).join(", ")
      : undefined;

  useEffect(() => {
    if (
      !projectLogos.length &&
      configLoader &&
      configLoader.projectLogos?.length
    ) {
      const projectLogos = configLoader.projectLogos.map((curr) => ({
        ...curr,
        texture_url: `${process.env.REACT_APP_API_ENDPOINT}${curr.texture_url}`,
        thumbnail_url: `${process.env.REACT_APP_API_ENDPOINT}${curr.thumbnail_url}`,
        asset_url: `${process.env.REACT_APP_API_ENDPOINT}${curr.asset_url}`,
      }));
      dispatch(setProjectLogos(projectLogos));
    }
  }, [configLoader, projectLogos, dispatch]);

  return (
    <ConfiguratorSelector
      tabKey={"projectLogos"}
      hasOptions={projectLogoPlacable}
      selected={projectLogoNames}
      title="config_option_title_5"
      onClick={(isOpen) => dispatch(productLogoPanelOpen(isOpen))}
    >
      <>
        {logoNames.map((name, index) => (
          <ProjectLogoItem key={name} name={name} data={projectLogos[index]} />
        ))}
      </>
    </ConfiguratorSelector>
  );
};

export { ProjectLogos };
