import React, { FC, useEffect } from "react";
import { ProductGroup } from "../types";
import { useGetDocumentByTypeQuery } from "../api/prismic";
import {
  selectConfigId,
  selectConfigLoader,
  setSelectedProductGroup,
} from "../store/reducers/configReducer";
import { useAppDispatch, useAppSelector } from "../hooks";
import Title from "./ui/Title";
import { getPrismicTranslation } from "../utils";
import PrismicTranslation from "./PrismicTranslation";
import { selectLanguage } from "../store/reducers/uiReducer";

const SplashScreen: FC = () => {
  const language = useAppSelector(selectLanguage);
  const configId = useAppSelector((state) => selectConfigId(state));
  const configLoader = useAppSelector((state) => selectConfigLoader(state));
  const { data, isError, isLoading } = useGetDocumentByTypeQuery({
    type: "product_group",
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (configId && configLoader && data?.length > 0) {
      const defaultProductGroup = data?.find(
        (productGroup: ProductGroup) =>
          productGroup.id === configLoader.productGroup,
      );
      dispatch(setSelectedProductGroup(defaultProductGroup));
    }
  }, [configLoader, configId, data, dispatch]);

  const setProductGroup = (option: ProductGroup) =>
    dispatch(setSelectedProductGroup(option));

  return (
    <div className="mx-4 my-14 xl:mx-auto bg-white xl:w-full max-w-[1430px] p-6 lg:p-12 rounded-md flex flex-1 flex-col">
      <div className="w-full flex justify-center">
        <Title size={1} label="splash_title" />
      </div>

      <div className="flex flex-1 flex-col md:flex-row gap-8 justify-between">
        {(isLoading || isError) &&
          Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className="cursor-pointer">
              <div className="mb-2 h-[492px] w-[370px] bg-gray animate-pulse"></div>
              <div className="h-6 w-3/4 bg-gray animate-pulse mb-2"></div>
              <div className="h-4 w-1/4 bg-gray animate-pulse mb-4"></div>
            </div>
          ))}
        {data &&
          data.map((option: ProductGroup) => (
            <div
              className="cursor-pointer flex flex-col w-full group"
              onClick={() => setProductGroup(option)}
              key={option.id}
            >
              <div
                className="flex flex-1 bg-cover bg-center min-h-[240px] mb-2 transform transition duration-500 group-hover:shadow-lg"
                style={{ backgroundImage: `url(${option.data.image.url})` }}
              ></div>
              <div>
                <Title
                  label={getPrismicTranslation(language, "name", option.data)}
                />
                <p className="text-neutrals-400 transform transition duration-200 underline group-hover:text-primary-500">
                  <PrismicTranslation field="splash_customize" simple />
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SplashScreen;
