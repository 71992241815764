import { ReactNode, useEffect, useState } from "react";
import { Logo } from "./ui/Logo";
import { MenuButton } from "./ui/MenuButton";
import { Country } from "../types";
import { CountrySelector } from "./ui/CountrySelector";
import {
  selectToken,
  selectUser,
  setCustomer,
  setDiscount,
  setLoading,
  setOrderOptions,
  setToken,
  setUser,
} from "../store/reducers/userReducer";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Button } from "./ui/Button";
import { UserManager } from "oidc-client";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLazyGetCustomerDataQuery } from "../api/texet";
import PrismicTranslation from "./PrismicTranslation";
import { countries, selectLanguage } from "../store/reducers/uiReducer";

export default function Layout({
  background,
  children,
  userManager,
}: {
  children?: ReactNode;
  background?: boolean;
  userManager: UserManager;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const accessToken = useAppSelector(selectToken);
  const language = useAppSelector(selectLanguage);
  const [selectedCountry, setSelectedCountry] = useState<Country>(language);
  const [getCustomerQuery, { data: customerData, isSuccess, isError }] =
    useLazyGetCustomerDataQuery();

  // get customer data callback on login , token is fetched via oidc-client
  useEffect(() => {
    if (accessToken) {
      dispatch(setLoading(true));
      getCustomerQuery(accessToken);
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    if (isError) {
      toast(<PrismicTranslation field="token_expired" simple />);
      logout();
    }
    if (isSuccess) {
      dispatch(setUser(customerData?.user));
      dispatch(setOrderOptions(customerData?.orderOptions));
      dispatch(setCustomer(customerData?.customer));
      dispatch(setDiscount(customerData?.discount));
      dispatch(setLoading(true));
    }
  }, [isSuccess, isError, customerData, accessToken, dispatch]);

  const logout = () => {
    userManager.signoutRedirect().then(() => {
      dispatch(setUser(null));
      dispatch(setCustomer(null));
      dispatch(setOrderOptions(null));
      dispatch(setToken(null));
      dispatch(setLoading(true));
    });
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex items-center flex-shrink-0 border-b border-neutrals-300 bg-white h-[72px] md:h-[88px]">
        <div className="px-4">
          <Logo />
        </div>
        <div className="flex ml-auto h-full">
          {user && user.id && (
            <MenuButton
              label="my_account"
              menuItems={[
                <div className="flex flex-wrap gap-2 text-sm">
                  <p className="p-2 w-full">
                    {user.fullName}, {user.customerName}
                  </p>
                  <p
                    className="p-2 border-gray border-y w-full"
                    onClick={() => navigate("/orders")}
                  >
                    <PrismicTranslation field="my_orders" simple />
                  </p>
                  <p
                    className="p-2 border-gray border-b w-full"
                    onClick={() => navigate("/projects")}
                  >
                    <PrismicTranslation field="my_projects" simple />
                  </p>
                  <Button
                    className="p-2"
                    onClick={logout}
                    label="logout"
                    link
                  />
                </div>,
              ]}
            />
          )}
          <CountrySelector
            country={selectedCountry}
            onSelect={setSelectedCountry}
            countries={countries}
          />
        </div>
      </div>
      <main
        className={`flex flex-1 flex-col ${
          background
            ? "bg-primaryDark bg-cover bg-center bg-no-repeat bg-splash bg-blend-overlay"
            : "bg-gray"
        }`}
      >
        {children}
      </main>
      <ToastContainer />
    </div>
  );
}
