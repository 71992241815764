import { PrismicRichText } from "@prismicio/react";
import { RichTextField } from "@prismicio/client";
import { selectTranslations } from "../store/reducers/uiReducer";
import { useAppSelector } from "../hooks";
import {get} from "lodash";

interface RichTextProps {
  simple?: boolean;
  field: string;
  options?: any;
}

const PrismicTranslation = ({ field, simple, options }: RichTextProps) => {
  const translations = useAppSelector(selectTranslations);
  if (!translations) {
    if (simple) {
      return "";
    }
    return null;
  }

  let translation = get(translations, field, false) as any; // any because of the PrismicRichText later
  if (!translation) return field;

  // replace dynamic values
  if (options) {
    const keys = Object.keys(options);
    keys.forEach((key) => {
      translation = translation.replace(`{${key}}`, options[key]);
    });
  }

  if (simple) {
    return translation;
  }
  return (
    <PrismicRichText
      fallback={translation}
      field={translation as RichTextField}
      components={{
        heading2: ({ children }) => (
          <h2 className="text-primary font-bold">{children}</h2>
        ),
      }}
    />
  );
};

export default PrismicTranslation;
