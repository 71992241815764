import React from "react";
import { ProjectLogoName } from "../../../types";
import { ProjectLogoEdit } from "./LogoItem";
import PrismicTranslation from "../../PrismicTranslation";

type LogoOptionProps = {
  name: ProjectLogoName;
  value: string;
  data: ProjectLogoEdit;
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
};

const LogoOption: React.FC<LogoOptionProps> = (props) => {
  const elementId = React.useId();

  return (
    <div
      className="flex items-center cursor-pointer"
      onClick={(e) => e.stopPropagation()}
    >
      <input
        id={elementId}
        name={`logo-option-${props.name}`}
        type="radio"
        value={props.value}
        checked={props.data?.option === props.value}
        onChange={props.onChange}
        className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
      />
      <label
        htmlFor={elementId}
        className="ml-3 block text-sm font-medium leading-6 text-gray-900"
      >
        <PrismicTranslation field={`logo-${props.value}`} simple />
      </label>
    </div>
  );
};

export { LogoOption };
