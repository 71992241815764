import React from "react";
import CloseIcon from "./Icons/CloseIcon";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  const modalClasses = isOpen ? "block" : "hidden";

  return (
    <div
      className={`fixed z-10 overflow-x-scroll md:fixed top-0 left-0 w-full h-full bg-white md:bg-black md:bg-opacity-40 ${modalClasses}`}
    >
      <div className="md:fixed md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 bg-white rounded-lg shadow-md w-full max-w-[500px]">
        <button
          onClick={onClose}
          className="absolute top-4 right-4  hover:scale-105 w-5 h-5 flex items-center p-0 justify-center fill-white background-red"
        >
          <CloseIcon className={`fill-primary-300 hover:fill-primary-500`} />
        </button>
        <div className="p-6">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
