import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const texetApi = createApi({
  reducerPath: "texetApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_PATH + "/texet",
    validateStatus: (response, result) => {
      return response.status >= 200 && response.status < 300 && !result.error;
    },
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getCustomerData: builder.query({
      query: (token?: string) => {
        return {
          url: `/customer`,
          method: "POST",
          body: {
            token,
          },
        };
      },
    }),
  }),
});

export const { useLazyGetCustomerDataQuery } = texetApi;
