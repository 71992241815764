import { useCallback } from "react";
import { useDownloadProductFicheMutation } from "../../../api/nest";
import { Product } from "../../../types";
import { useAppSelector } from "../../../hooks";
import { selectProductModelPicture } from "../../../store/reducers/configReducer";
import { selectCountryLanguage } from "../../../store/reducers/uiReducer";
import DownloadIcon from "../../ui/Icons/DownloadIcon";

const openDownload = true;
export const DownloadProductFiche = ({
  productConfig,
}: {
  productConfig: { id?: string; product?: Product };
}) => {
  const [downloadProductFiche] = useDownloadProductFicheMutation();
  const productImages = useAppSelector(selectProductModelPicture);
  const userLanguage = useAppSelector(selectCountryLanguage);

  const download = useCallback(() => {
    if (productConfig.id && productConfig.product) {
      downloadProductFiche({
        productFicheData: {
          productConfig: { id: productConfig.id },
          lang: userLanguage,
        },
        cb: async (response: any) => {
          if (!openDownload) {
            return;
          }
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "ProductFiche.pdf";
          a.click();
        },
      });
    }
  }, [downloadProductFiche, productImages, userLanguage]);

  return (
    <div
      onClick={download}
      className="cursor-pointer group border border-neutrals-300 rounded-full flex items-center bg-white p-2 align-middle absolute top-16 left-4 hover:bg-primary-200 hover:border-primary-300"
    >
      <DownloadIcon className="fill-neutrals-400 group-hover:fill-[#708EC8] pointer-events-none" />
    </div>
  );
};

export default DownloadProductFiche;
