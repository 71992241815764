import Title from "../../ui/Title";
import { ProductGroup } from "../../../types";
import React, { FC } from "react";
import { useAppSelector } from "../../../hooks";
import { selectLatestCartItem } from "../../../store/reducers/cartReducer";
import { useGetDocumentByTypeQuery } from "../../../api/prismic";
import CartProductCard from "./CartProductCard";

interface CompleteYourLookProps {
  setIsOpen?: (isOpen: boolean) => void;
}

const CompleteYourLook: FC<CompleteYourLookProps> = ({ setIsOpen }) => {
  const cartItem = useAppSelector((state) => selectLatestCartItem(state));
  const { data: productGroups } = useGetDocumentByTypeQuery({
    type: "product_group",
    lang: "en-us",
  });

  const completeYourLookProductGroups =
    productGroups?.filter(
      (productGroup: ProductGroup) =>
        productGroup.id !== cartItem?.productGroup?.id,
    ) || [];

  return (
    <>
      <Title label="add_to_cart_modal_complete_look" />
      <div className="flex flex-wrap">
        {completeYourLookProductGroups.map((productGroup: ProductGroup) => (
          <CartProductCard
            key={productGroup.id}
            productGroup={productGroup}
            cartItem={cartItem}
            setIsOpen={setIsOpen}
          />
        ))}
      </div>
    </>
  );
};

export default CompleteYourLook;
