import { ConfiguratorSelector } from "./ConfiguratorSelector";
import {
  selectConfigId,
  selectConfigLoader,
  selectProductGroup,
  setConfigId,
  setSelectedProduct,
  setSelectedProductGroup,
} from "../../store/reducers/configReducer";
import { ProductGroup } from "../../types";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useGetDocumentByTypeQuery } from "../../api/prismic";
import { getPrismicTranslation } from "../../utils";
import { selectLanguage } from "../../store/reducers/uiReducer";

const ProductGroupSelector = () => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const productGroup = useAppSelector((state) => selectProductGroup(state));
  const configId = useAppSelector((state) => selectConfigId(state));
  const configLoader = useAppSelector((state) => selectConfigLoader(state));
  const { data: productGroups } = useGetDocumentByTypeQuery({
    type: "product_group",
  });

  const onSelectProductGroup = (option: ProductGroup) => {
    dispatch(setSelectedProductGroup(option));
    dispatch(setSelectedProduct(undefined));
    dispatch(setConfigId(undefined));
  };

  const isSelected = (option: ProductGroup) => {
    return productGroup?.id === option.id;
  };

  useEffect(() => {
    if (
      configId &&
      configLoader &&
      productGroups?.length > 0 &&
      !productGroup
    ) {
      const defaultProductGroup = productGroups?.find(
        (productGroup: ProductGroup) =>
          productGroup.id === configLoader.productGroup,
      );
      dispatch(setSelectedProductGroup(defaultProductGroup));
    }
  }, [configLoader, configId, productGroup, productGroups, dispatch]);

  return (
    <ConfiguratorSelector
      tabKey={"productGroup"}
      hasOptions={productGroups?.length > 0}
      selected={getPrismicTranslation(language, "name", productGroup?.data)}
      title="config_option_title_1"
    >
      <div className="flex flex-wrap items-center justify-center gap-2">
        {productGroups?.map((option: ProductGroup) => (
          <div
            key={option.id}
            onClick={() => onSelectProductGroup(option)}
            className={`cursor-pointer font-bold text-center p-3 rounded flex-1 border border-gray text-sm
              ${
                isSelected(option)
                  ? "bg-primary-200 border-primary-300 "
                  : "hover:bg-neutrals-200 hover:border-neutrals-300"
              }`}
          >
            {getPrismicTranslation(language, "name", option?.data)}
          </div>
        ))}
      </div>
    </ConfiguratorSelector>
  );
};

export default ProductGroupSelector;
