import Modal from "./ui/Modal";
import Title from "./ui/Title";
import { Input } from "./ui/Input";
import { Button } from "./ui/Button";
import { useState } from "react";
import { useAppSelector } from "../hooks";
import {
  selectConfigId,
  selectProjectId,
} from "../store/reducers/configReducer";

interface ShareModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  project?: string;
}

const ShareModal = ({ isOpen, setIsOpen, project }: ShareModalProps) => {
  const projectId = useAppSelector((state) => selectProjectId(state));
  const configId = useAppSelector((state) => selectConfigId(state));
  const url = project
    ? `${window.location.origin}/${project}`
    : `${window.location.origin}/${projectId}/${configId}`;
  const [copied, setCopied] = useState(false);

  const copyLink = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  const close = () => {
    setIsOpen(false);
    setCopied(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Title label="share_modal_title" />
      <div className="flex w-full justify-between gap-2 align-middle max-w-[500px]">
        <Input value={url} type="text" readOnly />
        <Button
          onClick={copyLink}
          icon={copied ? "check" : undefined}
          label={!copied ? "copy" : ""}
        />
      </div>
    </Modal>
  );
};

export default ShareModal;
