import { FC } from "react";
import { Icon } from "./Icon";
import PrismicTranslation from "../PrismicTranslation";

interface TitleProps {
  label: string;
  size?: 1 | 2 | 3 | 4 | 5 | 6;
  secondary?: boolean;
  tertiary?: boolean;
  className?: string;
  icon?: string;
}

const Title: FC<TitleProps> = ({
  size = 2,
  label,
  secondary,
  tertiary,
  className,
  icon,
}) => {
  const Heading = `h${size}`;
  return (
    // @ts-ignore
    <Heading
      className={`${
        secondary ? "text-black" : tertiary ? "text-black" : "text-primary"
      } font-bold ${className || ""}`}
    >
      {icon && <Icon className="mt-2 mr-1" icon={icon} />}
      <PrismicTranslation field={label} simple />
    </Heading>
  );
};

export default Title;
