import { useAppDispatch, useAppSelector } from "../hooks";
import Title from "./ui/Title";
import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import { setProjectId } from "../store/reducers/configReducer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { selectUser } from "../store/reducers/userReducer";
import { useCreateProjectMutation } from "../api/nest";
import { toast } from "react-toastify";
import PrismicTranslation from "./PrismicTranslation";

const CreateProject = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const [hasError, setHasError] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>("");
  const [
    createProject,
    { data: createdProject, isSuccess, isError, isLoading },
  ] = useCreateProjectMutation();

  const handleChange = (value: string) => {
    setProjectName(value);
    if (value) {
      setHasError(false);
    }
  };

  const addProject = async () => {
    if (!projectName) {
      setHasError(true);
      return;
    }
    await createProject({
      name: projectName,
      userId: user?.id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setProjectId(createdProject.id));
      navigate(`/${createdProject.id}`);
    }
    if (isError) {
      toast.error(<PrismicTranslation field={"error_create_project"} simple />);
    }
  }, [isError, isSuccess, createdProject]);

  return (
    <div className="p-4 py-14">
      <div className="mx-auto rounded-md w-full p-6 md:p-6 max-w-5xl bg-white">
        <Title label="create_project_title" />
        <Input
          required
          isError={hasError}
          errorLabel="validation_projectname"
          alternateLabel
          value={projectName}
          onChange={(e) => handleChange(e.target.value)}
          label="create_project_name"
          placeholder={PrismicTranslation({
            field: "create_project_placeholder",
            simple: true,
          })}
        />
        <Button
          label="create_project"
          loading={isLoading}
          onClick={addProject}
        />
      </div>
    </div>
  );
};

export default CreateProject;
