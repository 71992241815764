import Title from "../ui/Title";
import {
  getCartItemPrice,
  getMininumOrderQuantity,
  getPriceDiscount,
} from "../../utils";
import { Button } from "../ui/Button";
import AddToCartModal from "../Cart/AddToCartModal/AddToCartModal";
import { CartProduct } from "../../types";
import {
  selectConfig,
  selectConfigId,
  selectFabricSupplier,
  selectIsEditing,
  setConfigId,
  setIsEditing,
} from "../../store/reducers/configReducer";
import { addToCart, selectCartItems } from "../../store/reducers/cartReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useState } from "react";
import { selectDiscount } from "../../store/reducers/userReducer";
import usePrevious from "../../hooks/usePrevious";
import { useCreateOrUpdateProductConfigMutation } from "../../api/nest";
import { isEqual, omit } from "lodash";
import PrismicTranslation from "../PrismicTranslation";
import { useGetDocumentByTypeQuery } from "../../api/prismic";

const ConfiguratorFooter = () => {
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const discount = useAppSelector(selectDiscount);
  const isEditing = useAppSelector(selectIsEditing);
  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);
  const configId = useAppSelector(selectConfigId);
  const cartItems = useAppSelector(selectCartItems);
  const fabricSupplier = useAppSelector(selectFabricSupplier);
  const previousConfig = usePrevious(config);
  const [createOrUpdateProductConfig, { isLoading, data }] =
    useCreateOrUpdateProductConfigMutation();

  const { data: settings } = useGetDocumentByTypeQuery({
    type: "settings",
    lang: "en-us",
  });

  const saveConfig = async () => {
    const inCart =
      configId !== undefined && cartItems.some((item) => item.id === configId);
    await createOrUpdateProductConfig({
      config,
      duplicate: false,
      inCart,
    });
    if (!data) return;
    const id = data?.id;
    if (!configId) {
      dispatch(setConfigId(id));
    }
  };

  const existingProduct = cartItems.find(
    (cartItem) => cartItem.id === configId,
  );
  const cartItem = {
    ...config,
    ...{
      amount: existingProduct?.amount || config.product?.data?.body,
    },
  } as CartProduct;

  const cartItemPrice = getCartItemPrice(cartItem, fabricSupplier);
  const discountPrice = getPriceDiscount(cartItemPrice, discount);
  const discountedCartItemPrice = cartItemPrice - discountPrice;

  const addProductToCart = async () => {
    await createOrUpdateProductConfig({
      config,
      duplicate: false,
      inCart: true,
    });
    if (!data) return;
    const id = data?.id;
    dispatch(setConfigId(id));
    await dispatch(addToCart({ ...cartItem, id }));
    setIsCartModalOpen(!isEditing);
    dispatch(setIsEditing(false));
  };

  // Save config on change
  useEffect(() => {
    // only save when config is fully ready
    if (
      isLoading ||
      !config.projectId ||
      !config.productGroup ||
      !config.product ||
      !config.fabric ||
      !config.productOptions
    ) {
      return;
    }
    // exclude productModelPicture from comparison because it triggers unneeded updates
    const exclude = ["productModelPicture"];
    if (!isEqual(omit(previousConfig, exclude), omit(config, exclude))) {
      saveConfig();
    }
  }, [dispatch, config, configId]);

  return (
    <div className="flex flex-col justify-center">
      <div className="text-center my-4">
        <div className="mb-0 justify-center flex flex-wrap gap-2">
          <Title label="unit_price" />
          <Title
            className={discount ? "line-through" : ""}
            label={`${cartItemPrice.toFixed(2)}€`}
          />
          {discount && (
            <Title label={`${discountedCartItemPrice.toFixed(2)}€`} />
          )}
        </div>
        <p className="text-text text-sm">
          <PrismicTranslation
            field={"min_order_quantity"}
            options={{
              min_order_quantity: getMininumOrderQuantity(settings),
            }}
            simple
          />
        </p>
      </div>
      <Button
        loading={isLoading}
        full
        icon="cart"
        label={isEditing ? "save_changes" : "add_to_cart"}
        onClick={addProductToCart}
      />
      <AddToCartModal isOpen={isCartModalOpen} setIsOpen={setIsCartModalOpen} />
    </div>
  );
};

export default ConfiguratorFooter;
