import React, { FC, ReactElement } from "react";
import { Icon } from "./Icon";
import { useOnClickOutside } from "../../hooks/useClickOutside";
import PrismicTranslation from "../PrismicTranslation";

export type MenuButtonProps = {
  icon?: string;
  className?: string;
  menuItems?: ReactElement[];
  label: string;
  onSelect?: (option: string) => void;
};

export const MenuButton: FC<MenuButtonProps> = ({
  icon,
  className,
  menuItems,
  label,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const clickRef = React.useRef<HTMLDivElement>(null);

  const handleSelect = (option: string) => {
    onSelect && onSelect(option);
    setIsOpen(false);
  };

  useOnClickOutside(clickRef, () => setIsOpen(false));

  return (
    <div
      ref={clickRef}
      className={`menu-button relative cursor-pointer 
        ${className || ""}`}
    >
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-center p-4 gap-2 border-l border-neutrals-300 h-full"
      >
        {icon && <Icon icon={icon} />}
        <div className="text-wrapper">
          <PrismicTranslation field={label} simple />
        </div>
      </div>

      {menuItems && isOpen && (
        <div className="absolute z-10 top-22 right-0 bg-white border border-neutrals-300">
          <div className="flex flex-col gap-0.5">
            {menuItems.map((item) => (
              <div
                onClick={(e) => handleSelect(e.currentTarget.innerText)}
                className="flex items-center gap-1"
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
