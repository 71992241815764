import {
  CartProduct,
  Fabric,
  ObjectWithProductOption,
  Product,
  ProductGroup,
  ProductOption,
} from "../../../types";
import React, { useEffect } from "react";
import { Button } from "../../ui/Button";
import { addToCart } from "../../../store/reducers/cartReducer";
import {
  selectProjectId,
  setConfigId,
  setSelectedConfig,
} from "../../../store/reducers/configReducer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useGetDocumentByTypeQuery } from "../../../api/prismic";
import { useCreateOrUpdateProductConfigMutation } from "../../../api/nest";
import { toast } from "react-toastify";
import { getPrismicTranslation } from "../../../utils";
import { selectLanguage } from "../../../store/reducers/uiReducer";
import PrismicTranslation from "../../PrismicTranslation";
type Props = {
  cartItem: CartProduct;
  productGroup: ProductGroup;
  setIsOpen?: (isOpen: boolean) => void;
};

const CartProductCard = ({ cartItem, setIsOpen, productGroup }: Props) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const projectId = useAppSelector((state) => selectProjectId(state));
  const { data: products } = useGetDocumentByTypeQuery({
    type: "product",
    lang: "en-us",
  });
  const { data: productOptions } = useGetDocumentByTypeQuery({
    type: "product_option",
    lang: "en-us",
  });

  const [
    createOrUpdateProductConfig,
    { data: createOrUpdateProductConfigResult, isError, isSuccess },
  ] = useCreateOrUpdateProductConfigMutation();

  const hasSameContrastFabric = (product: Product) =>
    product?.data?.contrast_fabrics?.some(
      (fabric) => fabric.fabric?.id === cartItem?.fabric?.contrast?.id,
    );

  const productGroupProducts =
    products?.filter(
      (product: Product) => product.data.parent.id === productGroup?.id,
    ) || [];
  const productWithSameFabrics = productGroupProducts?.find(
    (product: Product) => {
      return product.data?.main_fabrics?.find(
        (fabric) => fabric.fabric?.id === cartItem?.fabric?.main?.id,
      );
    },
  );
  const defaultProduct =
    productWithSameFabrics || (productGroupProducts?.[0] as Product);
  const defaultProductOptions = productOptions?.filter(
    (option: ProductOption) =>
      defaultProduct?.data?.product_options.find(
        (productOption: ObjectWithProductOption) =>
          productOption.product_option.id === option.id,
      ),
  );
  const enabledDefaultProductOptions = defaultProductOptions?.filter(
    (option: ProductOption) => option.data?.default,
  );

  const productConfig = {
    projectId,
    productGroup: productGroup,
    productOptions: enabledDefaultProductOptions,
    product: defaultProduct,
    fabric: {
      main: cartItem?.fabric?.main,
      contrast: hasSameContrastFabric(defaultProduct)
        ? cartItem?.fabric?.contrast
        : undefined,
    },
    projectLogos: [],
  };

  const addExtraProductToCart = async () => {
    await createOrUpdateProductConfig({
      config: productConfig,
      duplicate: true,
      inCart: true,
    });
  };

  const configureExtraProduct = () => {
    dispatch(setSelectedConfig(productConfig));
    //close modal if open
    setIsOpen && setIsOpen(false);
  };

  useEffect(() => {
    if (isError) {
      toast.error(<PrismicTranslation field={"create_config_error"} simple />);
    }
    if (isSuccess) {
      const { id } = createOrUpdateProductConfigResult;
      dispatch(setConfigId(id));
      dispatch(
        addToCart({
          ...productConfig,
          ...{
            id,
            amount: defaultProduct.data?.body,
          },
        }),
      );
    }
  }, [isError, isSuccess, createOrUpdateProductConfigResult]);

  return productWithSameFabrics && defaultProduct ? (
    <div
      key={defaultProduct?.id}
      className="flex flex-col w-full sm:w-1/2 p-2 gap-2 cursor-pointer text-sm"
    >
      <img
        src={"/placeholder.png"}
        alt={getPrismicTranslation(language, "name", defaultProduct.data)}
        width={40}
        height={40}
      />

      <span className="text-primary font-bold">
        {getPrismicTranslation(language, "name", productGroup?.data)}
      </span>
      {defaultProduct.data.price && (
        <span className="font-bold">{defaultProduct.data.price}€</span>
      )}
      <div className="flex flex-wrap items-center gap-2">
        <span className="font-bold">
          <PrismicTranslation field={"variant"} simple />:
        </span>
        <span>
          {getPrismicTranslation(language, "name", defaultProduct?.data)}
        </span>
      </div>
      <div className="flex flex-wrap items-center gap-2">
        <span className="font-bold">
          <PrismicTranslation field={"main_color"} simple />:
        </span>
        <div
          title={getPrismicTranslation(
            language,
            "name",
            cartItem?.fabric?.main?.data,
          )}
          className="w-2 h-2 rounded-full border"
          style={{
            backgroundColor: (cartItem?.fabric?.main as Fabric)?.data
              .web_format,
          }}
        />
        <span>
          {getPrismicTranslation(
            language,
            "name",
            cartItem?.fabric?.main?.data,
          )}
        </span>
      </div>
      {hasSameContrastFabric(defaultProduct) && (
        <div className="flex flex-wrap items-center gap-2">
          <span className="font-bold">
            <PrismicTranslation field={"contrast_color"} simple />:
          </span>
          <div
            title={getPrismicTranslation(
              language,
              "name",
              cartItem?.fabric?.contrast?.data,
            )}
            className="w-2 h-2 rounded-full border"
            style={{
              backgroundColor: (cartItem?.fabric?.contrast as Fabric)?.data
                .web_format,
            }}
          />
          <span>
            {getPrismicTranslation(
              language,
              "name",
              cartItem?.fabric?.contrast?.data,
            )}
          </span>
        </div>
      )}
      {enabledDefaultProductOptions?.length > 0 && (
        <div className="flex flex-wrap items-center gap-2">
          <span className="font-bold">
            <PrismicTranslation field={"options"} simple />:
          </span>

          <div>
            {enabledDefaultProductOptions.map((option: ProductOption) => (
              <span key={option.data.name}>
                {getPrismicTranslation(language, "name", option?.data)}
              </span>
            ))}
          </div>
        </div>
      )}
      <Button
        onClick={addExtraProductToCart}
        className="w-20"
        icon="cart"
        label="+"
      />
      <Button onClick={configureExtraProduct} link label="configure" />
    </div>
  ) : null;
};

export default CartProductCard;
