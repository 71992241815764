import React from "react";
import Files from "react-files";
import { Icon } from "../../ui/Icon";
import { ViewLoadingState } from "../../../types";
import CircularProgress from "../../ui/CircularProgress";
import PrismicTranslation from "../../PrismicTranslation";

interface FileUploadProps {
  onFileSelected: (file: File) => void;
  loadingState?: ViewLoadingState;
  statusMessage?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFileSelected,
  loadingState = "idle",
  statusMessage = "",
}) => {
  const handleFilesChange = (files: File[]) => {
    if (files.length > 0) {
      onFileSelected(files[0]);
    }
  };

  const handleFilesError = (error: Error, file: File) => {
    console.error("File error:", error.message, file);
  };

  return (
    <Files
      className="bg-gray rounded p-8 text-center w-full mb-2 flex flex-col items-center space-y-1"
      onChange={handleFilesChange}
      onError={handleFilesError}
      accepts={[".eps", ".ai"]}
      maxFiles={1}
      maxFileSize={1000 * 1024 * 1024 * 20} // in bytes
      minFileSize={0}
      clickable
    >
      <Icon icon="upload" />
      <div className="font-bold cursor-pointer">
        <PrismicTranslation field="drag_and_drop_to_upload" simple />
      </div>

      <div className="h-12 relative">
        {["idle", "succeeded"].includes(loadingState) && (
          <div className="text-gray-900">
            <PrismicTranslation field="upload_only_eps_or_ai" simple />
          </div>
        )}

        {loadingState === "pending" && (
          <div className="rounded-lg flex items-center gap-2 text-white p-1 align-middle absolute top-1/3 left-[calc(50%-20px)]">
            <CircularProgress />
          </div>
        )}

        {loadingState === "failed" && (
          <div>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
              <i className="color-red">{statusMessage}</i>
            </p>
          </div>
        )}
      </div>
    </Files>
  );
};

export { FileUpload };
