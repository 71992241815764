import React, { FC } from "react";
import { Flag } from "./Flag";
import { Icon } from "./Icon";
import { useOnClickOutside } from "../../hooks/useClickOutside";
import { Country } from "../../types";
import { setLanguage } from "../../store/reducers/uiReducer";
import { useAppDispatch } from "../../hooks";
import { languageMapping } from "../../utils";

export type CountrySelectorProps = {
  icon?: string;
  country?: Country;
  className?: string;
  countries?: Country[];
  onSelect?: (option: Country) => void;
};

export const CountrySelector: FC<CountrySelectorProps> = ({
  icon,
  country,
  className,
  countries,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const clickRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const handleSelect = (option: Country) => {
    onSelect && onSelect(option);
    setIsOpen(false);
    dispatch(setLanguage(option as Country));
  };

  useOnClickOutside(clickRef, () => setIsOpen(false));

  return (
    <div
      ref={clickRef}
      className={`menu-button relative cursor-pointer md:w-[150px] ${className}`}
    >
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-center p-2 gap-2 border-l border-neutrals-300 h-full"
      >
        {country && <Flag country={country} />}
        {icon && <Icon icon={icon} />}
        <div className="hidden md:block text-wrapper">
          {country && languageMapping[country]}
        </div>
      </div>

      {countries && isOpen && (
        <div className="absolute z-10 top-22 right-0 bg-white border border-neutrals-300">
          <div className="flex flex-col p-2 gap-2">
            {countries.map((country) => (
              <div
                key={country}
                onClick={() => handleSelect(country)}
                className="text-sm"
              >
                <span className="flex items-center gap-2 w-full">
                  <Flag country={country} /> {languageMapping[country]}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
