import { FC, useEffect, useContext } from "react";
import { Color3, Vector3 } from "@babylonjs/core/Maths/math";
import { SceneContext } from "react-babylonjs";
import { ProductOption } from "../../types";
import { CreateScreenshotUsingRenderTarget, FreeCamera } from "@babylonjs/core";

export type ProductModelPictureType = {
  onScreenshot?: (base64: string) => void;
  cameraRadius: number;
  selectedProductOptions: Array<ProductOption> | undefined;
  cameraTarget: Vector3;
  sceneFilename: string | undefined;
  fabricColorMap: Map<string, Color3>;
};

const ProductModelPicture: FC<ProductModelPictureType> = (props) => {
  const {
    selectedProductOptions,
    sceneFilename,
    onScreenshot,
    cameraTarget,
    cameraRadius,
    fabricColorMap,
  } = props;
  const ctx = useContext(SceneContext);

  useEffect(() => {
    if (!ctx.scene || !onScreenshot) {
      return;
    }
    const engine = ctx.scene.getEngine();
    const camera = ctx.scene.getNodeByName(
      "product-picture-camera0",
    ) as FreeCamera;
    // react-babylonjs seems to has issues with cameras not updating. Setting target and position directly to be sure.
    camera.target = cameraTarget;
    camera.position = cameraTarget.add(new Vector3(0, 0, cameraRadius));
    //
    CreateScreenshotUsingRenderTarget(engine, camera, 400, onScreenshot);
  }, [
    ctx.scene,
    onScreenshot,
    selectedProductOptions,
    sceneFilename,
    cameraTarget,
    cameraRadius,
    fabricColorMap,
  ]);

  return (
    <freeCamera
      name="product-picture-camera0"
      target={props.cameraTarget}
      minZ={0.001}
      position={props.cameraTarget.add(new Vector3(0, 0, cameraRadius))}
    />
  );
};

export { ProductModelPicture };
