import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { CartProduct, Size, SizingObject } from "../../types";

interface CartState {
  products: CartProduct[];
}

const initialState: CartState = {
  products: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<CartProduct>) => {
      const existingProduct = state.products?.find(
        (product) => product.id === action.payload.id,
      );
      //update old entry
      if (existingProduct) {
        const productIndex = state.products.indexOf(existingProduct);
        state.products[productIndex] = action.payload;
      } else {
        state.products = [...state.products, action.payload];
      }
    },
    removeFromCart: (state, action: PayloadAction<CartProduct>) => {
      const hasProduct = state.products?.find(
        (product) => product.id === action.payload.id,
      );
      if (hasProduct) {
        state.products = state.products?.filter(
          (product) => product.id !== action.payload.id,
        );
      }
    },
    updateAmount: (
      state,
      action: PayloadAction<{
        gender: string;
        id: string;
        size: any;
        amount: number;
      }>,
    ) => {
      const hasProduct = state.products?.find(
        (product) => product.id === action.payload.id,
      );
      if (hasProduct) {
        state.products = state.products?.map((product) => {
          if (product.id === action.payload.id) {
            const genderOption = product.amount?.find(
              (sizingObject: SizingObject) =>
                sizingObject.primary.translation_key === action.payload.gender,
            );
            const sizeOption = genderOption?.items.find(
              (optionSize: Size) => optionSize.size === action.payload.size,
            );
            sizeOption.amount = action.payload.amount;
          }
          return product;
        });
      }
    },
  },
});

export const { addToCart, removeFromCart, updateAmount } = cartSlice.actions;

export const selectCart = (state: RootState) => state.cart;
export const selectCartItems = (state: RootState) => state.cart.products;
export const selectLatestCartItem = (state: RootState) => {
  const cart = state.cart.products;
  return cart[cart.length - 1];
};

export default cartSlice.reducer;
