import { useEffect } from "react";
import { UserManager } from "oidc-client";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { selectUser, setToken } from "../../store/reducers/userReducer";
import { selectCart } from "../../store/reducers/cartReducer";
import Layout from "../Layout";
import { Icon } from "../ui/Icon";
import Title from "../ui/Title";
import Login from "../Login";
import CartItem from "./CartItem";
import { Button } from "../ui/Button";
import OrderSummary from "../Checkout/OrderSummary";
import { getMininumOrderQuantity, totalCartProductAmount } from "../../utils";
import { selectFabricSupplier, selectProjectId } from "../../store/reducers/configReducer";
import PrismicTranslation from "../PrismicTranslation";
import { useGetDocumentByTypeQuery } from "../../api/prismic";

const Cart = ({ userManager }: { userManager: UserManager }) => {
  const navigate = useNavigate();
  const projectId = useAppSelector(selectProjectId);
  const user = useAppSelector(selectUser);
  const cart = useAppSelector(selectCart);
  const fabricSupplier = useAppSelector(selectFabricSupplier);
  const dispatch = useAppDispatch();
  const isLoggedIn = !!user;

  const { data: settings } = useGetDocumentByTypeQuery({
    type: "settings",
    lang: "en-us",
  });

  const isCartValid =
    cart.products?.length > 0 &&
    cart.products?.every(
      (product) =>
        totalCartProductAmount(product) >= getMininumOrderQuantity(settings),
    );

  useEffect(() => {
    !user &&
      userManager.getUser().then((currentUser) => {
        dispatch(setToken(currentUser?.access_token));
      });
  }, [dispatch, user, userManager]);

  useEffect(() => {
    if (cart.products.length === 0) {
      navigate("/" + projectId);
    }
  }, [cart]);

  return (
    <Layout userManager={userManager} background>
      {isLoggedIn ? (
        <div className="flex flex-wrap flex-1 h-full">
          <div className="bg-gray flex flex-col w-full lg:w-2/3 pt-4 lg:px-10">
            <div
              onClick={() => navigate(`/${projectId || ""}`)}
              className="cursor-pointer w-full flex items-center gap-2 ml-2 underline text-text text-sm"
            >
              <Icon icon="back" size="small" />
              <span>
                <PrismicTranslation field={"continue_shopping"} simple />
              </span>
            </div>
            <Title size={1} label="cart" />
            {cart.products?.map((cartItem) => (
              <CartItem key={cartItem.id} cartItem={cartItem} fabricSupplier={fabricSupplier} />
            ))}
          </div>
          <div className="flex flex-col p-10 w-full lg:w-1/3 bg-white text-base font-bold">
            <OrderSummary showTitle showWarning fabricSupplier={fabricSupplier}  />
            <div className="pr-4 pt-4 lg:fixed lg:bottom-8 bg-white">
              <Button
                full
                disabled={!isCartValid}
                onClick={() => navigate("/delivery")}
                label="proceed_delivery"
              />
              <p className="text-text font-medium text-sm mt-4">
                <PrismicTranslation field="order_summary_warning_2" simple />
              </p>
            </div>
          </div>
        </div>
      ) : (
        <Login userManager={userManager} />
      )}
    </Layout>
  );
};

export default Cart;
