import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import {
  BaseColorOption,
  ConfigLoader,
  Fabric,
  ObjectWithId,
  Product,
  ProductGroup,
  ProductOption,
  ProjectLogo,
  ProjectLogoName,
} from "../../types";
import {
  calcProductOptionVariantPrice,
  isProductOptionWithLogo,
} from "../../utils";
import { get } from "lodash";

interface ConfigState {
  isEditing?: boolean;
  id?: string;
  projectId?: string;
  configLoader?: ConfigLoader;
  productGroup?: ProductGroup;
  product?: Product;
  fabric?: {
    main?: Fabric;
    contrast?: Fabric;
  };
  fabricSupplier: ObjectWithId | null;
  productOptions: ProductOption[];
  productModelPicture: string | undefined; // base64 png
  projectLogos: ProjectLogo[];
}

const initialState: ConfigState = {
  isEditing: false,
  id: undefined,
  projectId: undefined,
  productGroup: undefined,
  product: undefined,
  fabric: undefined,
  fabricSupplier: null,
  productOptions: [],
  productModelPicture: undefined,
  projectLogos: [],
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setIsEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
    setConfigLoader: (state, action: PayloadAction<ConfigLoader>) => {
      state.configLoader = action.payload;
    },
    setConfigId: (state, action: PayloadAction<string | undefined>) => {
      state.id = action.payload;
    },
    setProjectId: (state, action: PayloadAction<string | undefined>) => {
      state.projectId = action.payload;
    },
    setProductId: (state, action: PayloadAction<string>) => {
      state.projectId = action.payload;
    },
    setSelectedProductGroup: (
      state,
      action: PayloadAction<ProductGroup | undefined>,
    ) => {
      state.productGroup = action.payload;
      state.productOptions = [];
      state.fabric = undefined;
      state.fabricSupplier = null;
    },
    setSelectedProduct: (state, action: PayloadAction<Product | undefined>) => {
      state.product = action.payload;
      state.productOptions = [];
      state.productModelPicture = undefined;
      state.fabric = undefined;
      state.fabricSupplier = null;
    },
    setSelectedFabric: (
      state,
      action: PayloadAction<{
        fabric: Fabric;
        colorOption: BaseColorOption;
      }>,
    ) => {
      state.fabric = {
        ...(state.fabric || {}),
        [action.payload.colorOption]: action.payload.fabric,
      };
      state.fabricSupplier = action.payload.fabric.data?.supplier || null;
    },
    setSelectedProductOptions: (
      state,
      action: PayloadAction<ProductOption[]>,
    ) => {
      state.productOptions = filterValidProductOptions(
        setProductOptionVariantPrice(action.payload, state),
      );
    },
    setProductModelScreenshot: (state, action: PayloadAction<string>) => {
      state.productModelPicture = action.payload;
    },
    setSelectedConfig: (state, action: PayloadAction<Partial<ConfigState>>) => {
      state.productGroup = action.payload.productGroup;
      state.product = action.payload.product;
      state.fabric = action.payload.fabric;
      state.fabricSupplier = get(action, "payload.fabric.main", null);
      state.productOptions = action.payload.productOptions || [];
      state.id = action.payload.id;
      state.isEditing = action.payload.isEditing;
    },
    setProjectLogo: (state, action: PayloadAction<ProjectLogo>) => {
      const projectLogo = action.payload;
      state.projectLogos = [
        ...state.projectLogos.filter((curr) => curr.id !== projectLogo.id),
        projectLogo,
      ];
    },
    setProjectLogos: (state, action: PayloadAction<ProjectLogo[]>) => {
      state.projectLogos = action.payload;
    },
    updateProjectLogo: (state, action: PayloadAction<ProjectLogo>) => {
      const projectLogo = action.payload;
      const current = state.projectLogos.find(
        (curr) => curr.id === projectLogo.id,
      );
      if (!current) {
        state.projectLogos.push(action.payload);
      } else {
        state.projectLogos = [
          ...state.projectLogos.filter((curr) => curr.id !== projectLogo.id),
          {
            ...current,
            ...action.payload,
          },
        ];
        // Update pricing for the selected ProjectOptions
        if (state.productOptions) {
          state.productOptions = filterValidProductOptions(
            setProductOptionVariantPrice(state.productOptions, state),
          );
        }
      }
    },
    removeProjectLogo: (state, action: PayloadAction<ProjectLogoName>) => {
      state.projectLogos = [
        ...state.projectLogos.filter((curr) => curr.name !== action.payload),
      ];
      // Update pricing for the selected ProjectOptions
      if (state.productOptions) {
        state.productOptions = filterValidProductOptions(
          setProductOptionVariantPrice(state.productOptions, state),
        );
      }
    },
  },
});

export const {
  setIsEditing,
  setConfigLoader,
  setConfigId,
  setProjectId,
  setSelectedProductGroup,
  setSelectedProduct,
  setSelectedFabric,
  setSelectedProductOptions,
  setSelectedConfig,
  setProductModelScreenshot,
  setProjectLogos,
  updateProjectLogo,
  removeProjectLogo,
} = configSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectIsEditing = (state: RootState) => state.config.isEditing;
export const selectConfigLoader = (state: RootState) =>
  state.config.configLoader;
export const selectConfigId = (state: RootState) => state.config.id;
export const selectProjectId = (state: RootState) => state.config.projectId;
export const selectProductGroup = (state: RootState) =>
  state.config.productGroup;
export const selectProduct = (state: RootState): Product | undefined =>
  state.config.product;
export const selectFabric = (
  state: RootState,
): { main?: Fabric; contrast?: Fabric } | undefined => state.config.fabric;
export const selectFabricSupplier = (state: RootState) =>
  state.config.fabricSupplier;
export const selectProductOptions = (state: RootState): ProductOption[] =>
  state.config.productOptions;
export const selectProductModelPicture = (state: RootState) =>
  state.config.productModelPicture;
export const selectProjectLogos = (state: RootState) =>
  state.config.projectLogos;
export const selectProjectLogoPlacable = (state: RootState) =>
  typeof state.config.product !== "undefined";

const filterValidProductOptions = (productOptions: ProductOption[]) =>
  productOptions.filter((curr) => curr !== null);

function setProductOptionVariantPrice(
  productOptions: ProductOption[],
  state: ConfigState,
) {
  return productOptions.map((productOption) => {
    if (isProductOptionWithLogo(productOption)) {
      const variantPrice = calcProductOptionVariantPrice(
        productOption,
        state.projectLogos,
        state.fabricSupplier,
      );
      if (variantPrice !== false) {
        return {
          ...productOption,
          data: {
            ...productOption.data,
            price: variantPrice,
          },
        };
      }
    }
    return productOption;
  });
}

export const selectConfig = (state: RootState) => state.config;

export default configSlice.reducer;
