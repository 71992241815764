import { BaseColorOption, Fabric, FabricColor, Supplier } from "../../../types";
import { FC } from "react";
import Title from "../../ui/Title";
import { setSelectedFabric } from "../../../store/reducers/configReducer";
import { useAppDispatch } from "../../../hooks";
import ColorOption from "./ColorOption";

interface FabricColorProps {
  defaultOption?: FabricColor;
  fabrics?: Fabric[];
  supplier: Supplier;
  colorOption: BaseColorOption;
  disabled?: boolean;
}

export const SupplierFabricColors: FC<FabricColorProps> = ({
  defaultOption,
  fabrics,
  supplier,
  colorOption,
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const selectFabric = (colorOption: BaseColorOption, fabric: Fabric) => {
    dispatch(setSelectedFabric({ fabric, colorOption }));
  };

  return (
    <div className="flex flex-wrap gap-1">
      <div className="w-full mb-2">
        <Title secondary size={3} label={`${supplier.data?.product_line}`} />
        <div className="flex flex-wrap gap-1">
          {fabrics?.map((option) => (
            <ColorOption
              prefix={colorOption}
              option={option}
              key={`${colorOption}-${option.id}`}
              defaultOption={defaultOption}
              onClick={selectFabric}
              disabled={disabled}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SupplierFabricColors;
