import { WebStorageStateStore } from "oidc-client";

export const oidcConfig = {
  userStore: new WebStorageStateStore({
    store: localStorage,
  }),
  authority: process.env.REACT_APP_OIDC_API,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI,
  response_type: "code",
  scope: `openid profile offline_access commerce`,
  post_logout_redirect_uri: process.env.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
};
