import React from "react";

interface DownloadIconProps {
  className: string;
}

export const DownloadIcon = ({ className }: DownloadIconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00296 10.5299C9.00164 10.5102 9.00098 10.4904 9.00098 10.4705V2.94117C9.00098 2.69156 9.10633 2.45217 9.29386 2.27566C9.48139 2.09916 9.73574 2 10.001 2C10.2662 2 10.5205 2.09916 10.708 2.27566C10.8956 2.45217 11.0009 2.69156 11.0009 2.94117V10.4705C11.0009 10.4726 11.0009 10.4747 11.0009 10.4768L13.4002 8.77648C13.6124 8.62671 13.8791 8.56241 14.1416 8.59771C14.4042 8.63301 14.6411 8.76502 14.8002 8.96472C14.9593 9.16441 15.0277 9.41541 14.9901 9.66252C14.9526 9.90963 14.8124 10.1326 14.6002 10.2824L10.6003 13.1059C10.4272 13.2281 10.2167 13.2941 10.0003 13.2941C9.793 13.2956 9.59033 13.2364 9.42033 13.1247L5.42043 10.4706C5.20485 10.3267 5.05858 10.1082 5.01361 9.86309C4.96864 9.61794 5.02862 9.366 5.18043 9.16236C5.25622 9.06057 5.35267 8.97392 5.46423 8.9074C5.57579 8.84089 5.70024 8.79583 5.8304 8.77483C5.96056 8.75383 6.09385 8.7573 6.22258 8.78505C6.35131 8.81279 6.47292 8.86426 6.5804 8.93648L9.00296 10.5299ZM2 15.1765V17.0587V17.0588C2 17.5786 2.4477 18 2.99998 18L3.01407 17.9999H16.9859L17 18C17.5522 18 18 17.5786 18 17.0588V15.1765C18 14.6567 17.5522 14.2353 17 14.2353C16.4477 14.2353 16 14.6567 16 15.1765V16.1176H3.99995V15.1765C3.99995 14.6567 3.55225 14.2353 2.99998 14.2353C2.4477 14.2353 2 14.6567 2 15.1765Z"
      fill="fill-current"
    />
  </svg>
);

export default DownloadIcon;
