import { RefObject, useEffect } from "react";

type Handler = (event: MouseEvent) => void;

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
): void {
  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
      handler(e);
    }
  };

  useEffect(() => {
    document?.addEventListener("click", handleClick);
    return () => {
      document?.removeEventListener("click", handleClick);
    };
  });
}
