import { FC, ReactElement } from "react";
import Title from "../ui/Title";
import { Icon } from "../ui/Icon";
import { selectActiveTab, setActiveTab } from "../../store/reducers/uiReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import PrismicTranslation from "../PrismicTranslation";

interface ConfiguratorSelectorProps {
  title?: string;
  tabKey: string;
  hasOptions: boolean;
  selected?: string;
  children: ReactElement;
  onClick?: (isOpen: boolean) => void;
}

export const ConfiguratorSelector: FC<ConfiguratorSelectorProps> = ({
  title,
  tabKey,
  hasOptions,
  selected,
  children,
  onClick,
}) => {
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector(selectActiveTab);
  const isAlreadyOpen = activeTab === tabKey;

  const handleClick = async () => {
    onClick && onClick(!isAlreadyOpen);
    await dispatch(setActiveTab(isAlreadyOpen ? "" : tabKey));
  };

  const shouldShow = tabKey && activeTab === tabKey && hasOptions;

  return (
    <div className="items-flex-start flex flex-col gap-2 p-3 relative border-b border-neutrals-300">
      <div className="head">
        <div className="relative">
          <div onClick={handleClick} className="mb-2 cursor-pointer">
            {title && <Title label={title} />}
            <p className="text-text text-xs">
              {!!selected ? (
                selected
              ) : (
                <PrismicTranslation field={"no_selection"} simple />
              )}
            </p>
            {hasOptions && (
              <Icon
                size="medium"
                className="absolute right-0 top-2 text-primary-300"
                icon={!shouldShow ? "chevron-down" : "chevron-up"}
              />
            )}
          </div>
        </div>
      </div>
      {shouldShow && <>{children}</>}
    </div>
  );
};
