import { ConfiguratorSelector } from "../ConfiguratorSelector";
import {
  selectConfigId,
  selectConfigLoader,
  selectProduct,
  selectProductOptions,
  setSelectedProductOptions,
  selectFabricSupplier,
} from "../../../store/reducers/configReducer";
import { Option, Product, ProductOption } from "../../../types";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useGetDocumentByTypeQuery } from "../../../api/prismic";
import ProductFeature from "./ProductFeature";
import { sortProductOptionsByPriority, updateProductOption } from "../../../utils";

const FeatureSelector = () => {
  const dispatch = useAppDispatch();
  const configId = useAppSelector((state) => selectConfigId(state));
  const configLoader = useAppSelector((state) => selectConfigLoader(state));
  const product = useAppSelector<Product | undefined>((state) =>
    selectProduct(state),
  );
  const selectedProductOptions = useAppSelector<ProductOption[] | undefined>(
    (state) => selectProductOptions(state),
  );

  const fabricSupplier = useAppSelector(selectFabricSupplier);

  const { data: productOptions, isSuccess } = useGetDocumentByTypeQuery({
    type: "product_option",
    lang: "en-us",
  });

  const getProductSpecOptions = () => {
    if (!product || !productOptions) return [];

    return productOptions
      .filter((productOption: ProductOption) =>
        product.data.product_options.some(
          (mainFabric: any) =>
            mainFabric.product_option.id === productOption.id,
        ),
      )
      .sort(sortProductOptionsByPriority(product));
  };

  useEffect(() => {
    if (!productOptions?.length) return;

    const options = getProductSpecOptions();

    if (!selectedProductOptions?.length && options?.length) {
      if (configId && configLoader) {
        const selectedOptions = productOptions.filter(
          (option: ProductOption) =>
            configLoader.productOptions?.some(
              (productConfigOption) => productConfigOption.id === option.id,
            ),
        );
        dispatch(
          setSelectedProductOptions(
            selectedOptions.map((option: ProductOption) => ({
              ...option,
              data: {
                ...option.data,
                color_option: configLoader.productOptions?.find(
                  (productOption) => productOption.id === option.id,
                )?.colorOption,
              },
            })),
          ),
        );
      } else {
        dispatch(
          setSelectedProductOptions(
            options
              .filter((option: ProductOption) => option.data.default)
              .map((option: ProductOption) => ({
                ...option,
                data: {
                  ...option.data,
                  color_option: option.data.color_option || "main",
                },
              })),
          ),
        );
      }
    }
  }, [isSuccess, productOptions, product]);

  // Remove ProductOption and related ProductOptions
  const handleOptionsOnRemove = (option: ProductOption): ProductOption[] => {
    if (!selectedProductOptions) return [];
    // List of all ProductOptions ids to remove from selected productOptions
    const unselectProductOptions = [
      option.id,
      ...option.data.disable_exit_options.map((item) => item.option.id),
    ];
    return selectedProductOptions.filter(
      (item) => !unselectProductOptions.includes(item.id),
    );
  };

  const handleConflictOptions = (option: ProductOption): ProductOption[] => {
    if (!selectedProductOptions) return [];
    let newOptions = [
      ...selectedProductOptions.filter((curr) => curr.id !== option.id),
      option,
    ];
    // disable conflicting options
    option.data.disable_options?.forEach((disableOption: Option) => {
      newOptions = newOptions.filter(
        (item) => item?.id !== disableOption?.option.id,
      );
    });
    // enable options that are locked by this option
    option.data.enable_options?.forEach((enableOption: Option) => {
      newOptions = [
        ...newOptions,
        productOptions.find(
          (option: ProductOption) => option.id === enableOption.option.id,
        ) as ProductOption,
      ];
    });

    return newOptions;
  };

  const addOrRemoveOption = (option: ProductOption) => {
    if (!selectedProductOptions) return;

    const isSelected = selectedProductOptions.some(
      (item) => item?.id === option?.id,
    );

    if (isSelected) {
      dispatch(setSelectedProductOptions(handleOptionsOnRemove(option)));
    } else {
      dispatch(setSelectedProductOptions(handleConflictOptions(option)));
    }
  };

  const onOptionChange = (option: ProductOption) => {
    if (!selectedProductOptions) return;
    dispatch(
      setSelectedProductOptions(
        updateProductOption(option, handleConflictOptions(option)),
      ),
    );
  };

  return (
    <ConfiguratorSelector
      tabKey={"options"}
      hasOptions={getProductSpecOptions()?.length > 0}
      selected={selectedProductOptions
        ?.map((option) => option?.data?.name)
        .join(", ")}
      title="config_option_title_4"
    >
      <div className="flex flex-col flex-wrap items-center justify-center gap-1">
        {getProductSpecOptions()?.map((option: ProductOption) => (
          <ProductFeature
            key={option.id}
            defaultOption={selectedProductOptions}
            selectOption={addOrRemoveOption}
            onColorChange={onOptionChange}
            option={option}
            selectedProductOptions={selectedProductOptions}
            fabricSupplier={fabricSupplier}
          />
        ))}
      </div>
    </ConfiguratorSelector>
  );
};

export default FeatureSelector;
